import messages from "@/locales/websecmap"
import internet_nl_messages from "@/locales/internet_nl"

const internet_nl_field_mapping = {
  internet_nl_web_tls: 'test_sitetls',
  internet_nl_web_dnssec: 'test_sitednssec',
  internet_nl_web_ipv6: 'test_siteipv6',

  internet_nl_mail_dashboard_tls: 'test_mailtls',
  internet_nl_mail_tls: 'test_mailtls',
  internet_nl_mail_dashboard_auth: 'test_mailauth',
  internet_nl_mail_dashboard_dnssec: 'test_maildnssec',
  internet_nl_mail_dashboard_ipv6: 'test_mailipv6',

  internet_nl_web_appsecpriv: 'test_siteappsecpriv',
  internet_nl_web_appsecpriv_csp: 'detail_web_appsecpriv_http_csp',
  internet_nl_web_appsecpriv_referrer_policy: 'detail_web_appsecpriv_http_referrer_policy',
  internet_nl_web_appsecpriv_x_content_type_options: 'detail_web_appsecpriv_http_x_content_type',
  internet_nl_web_appsecpriv_x_frame_options: 'detail_web_appsecpriv_http_x_frame',
  internet_nl_web_appsecpriv_x_xss_protection: 'detail_web_appsecpriv_http_x_xss',
  internet_nl_wsm_web_appsecpriv_securitytxt: 'detail_web_appsecpriv_http_securitytxt',
  internet_nl_web_https_cert_domain: 'detail_web_tls_cert_hostmatch',
  internet_nl_web_https_http_redirect: 'detail_web_tls_https_forced',
  internet_nl_web_https_cert_chain: 'detail_web_tls_cert_trust',
  internet_nl_web_https_tls_version: 'detail_web_tls_version',
  internet_nl_web_https_tls_clientreneg: 'detail_web_tls_renegotiation_client',
  internet_nl_web_https_tls_ciphers: 'detail_web_tls_ciphers',
  internet_nl_web_https_http_available: 'detail_web_tls_https_exists',
  internet_nl_web_https_dane_exist: 'detail_web_tls_dane_exists',
  internet_nl_web_https_http_compress: 'detail_web_tls_http_compression',
  internet_nl_web_https_http_hsts: 'detail_web_tls_https_hsts',
  internet_nl_web_https_tls_secreneg: 'detail_web_tls_renegotiation_secure',
  internet_nl_web_https_dane_valid: 'detail_web_tls_dane_valid',
  internet_nl_web_https_cert_pubkey: 'detail_web_tls_cert_pubkey',
  internet_nl_web_https_cert_sig: 'detail_web_tls_cert_signature',
  internet_nl_web_https_tls_compress: 'detail_web_tls_compression',
  internet_nl_web_https_tls_keyexchange: 'detail_web_tls_fs_params',
  internet_nl_web_dnssec_valid: 'detail_web_dnssec_valid',
  internet_nl_web_dnssec_exist: 'detail_web_dnssec_exists',
  internet_nl_web_ipv6_ws_similar: 'detail_web_ipv6_web_ipv46',
  internet_nl_web_ipv6_ws_address: 'detail_web_ipv6_web_aaaa',
  internet_nl_web_ipv6_ns_reach: 'detail_web_mail_ipv6_ns_reach',
  internet_nl_web_ipv6_ws_reach: 'detail_web_ipv6_web_reach',
  internet_nl_web_ipv6_ns_address: 'detail_web_mail_ipv6_ns_aaaa',

  // added with api 2.0.0
  internet_nl_web_https_tls_cipherorder: 'detail_web_tls_cipher_order',
  internet_nl_web_https_tls_0rtt: 'detail_web_tls_zero_rtt',
  internet_nl_web_https_tls_ocsp: 'detail_web_tls_ocsp_stapling',
  internet_nl_web_https_tls_keyexchangehash: 'detail_web_tls_kex_hash_func',

  internet_nl_mail_ipv6: 'test_mailipv6',
  internet_nl_mail_starttls_cert_domain: 'detail_mail_tls_cert_hostmatch',
  internet_nl_mail_starttls_tls_version: 'detail_mail_tls_version',
  internet_nl_mail_starttls_cert_chain: 'detail_mail_tls_cert_trust',
  internet_nl_mail_starttls_tls_available: 'detail_mail_tls_starttls_exists',
  internet_nl_mail_starttls_tls_clientreneg: 'detail_mail_tls_renegotiation_client',
  internet_nl_mail_starttls_tls_ciphers: 'detail_mail_tls_ciphers',
  internet_nl_mail_starttls_dane_valid: 'detail_mail_tls_dane_valid',
  internet_nl_mail_starttls_dane_exist: 'detail_mail_tls_dane_exists',
  internet_nl_mail_starttls_tls_secreneg: 'detail_mail_tls_renegotiation_secure',
  internet_nl_mail_starttls_dane_rollover: 'detail_mail_tls_dane_rollover',
  internet_nl_mail_starttls_cert_pubkey: 'detail_mail_tls_cert_pubkey',
  internet_nl_mail_starttls_cert_sig: 'detail_mail_tls_cert_signature',
  internet_nl_mail_starttls_tls_compress: 'detail_mail_tls_compression',
  internet_nl_mail_starttls_tls_keyexchange: 'detail_mail_tls_fs_params',
  internet_nl_mail_auth_dmarc_policy: 'detail_mail_auth_dmarc_policy',
  internet_nl_mail_auth_dmarc_exist: 'detail_mail_auth_dmarc',
  internet_nl_mail_auth_spf_policy: 'detail_mail_auth_spf_policy',
  internet_nl_mail_auth_dkim_exist: 'detail_mail_auth_dkim',
  internet_nl_mail_auth_spf_exist: 'detail_mail_auth_spf',
  internet_nl_mail_dnssec_mailto_exist: 'detail_mail_dnssec_exists',
  internet_nl_mail_dnssec_mailto_valid: 'detail_mail_dnssec_valid',
  internet_nl_mail_dnssec_mx_valid: 'detail_mail_dnssec_mx_valid',
  internet_nl_mail_dnssec_mx_exist: 'detail_mail_dnssec_mx_exists',
  internet_nl_mail_ipv6_mx_address: 'detail_mail_ipv6_mx_aaaa',
  internet_nl_mail_ipv6_mx_reach: 'detail_mail_ipv6_mx_reach',
  internet_nl_mail_ipv6_ns_reach: 'detail_web_mail_ipv6_ns_reach',
  internet_nl_mail_ipv6_ns_address: 'detail_web_mail_ipv6_ns_aaaa',

  // added with api2.0
  internet_nl_mail_starttls_tls_cipherorder: 'detail_mail_tls_cipher_order',
  internet_nl_mail_starttls_tls_keyexchangehash: 'detail_mail_tls_kex_hash_func',
  internet_nl_mail_starttls_tls_0rtt: 'detail_mail_tls_zero_rtt',

  // rpki:
  internet_nl_web_rpki_exists: 'detail_web_rpki_exists',
  internet_nl_mail_rpki_exists: 'detail_mail_rpki_exists',

  internet_nl_detail_tech_data_http_securitytxt: 'detail_tech_data_http_securitytxt',
};

// categories are translated with _passed_summary or _failed_summary. The rest with their label. Should be in
// the retrieved translations anyway.
// todo: why not convert these files instead of re-converting them every page load?
function import_field_translations(language) {

  let mapping = {};
  for (let key of Object.keys(internet_nl_field_mapping)) {
    let subfields = get_subtranslations(internet_nl_field_mapping[key]);
    subfields.forEach((subfield) => {
      mapping[key + subfield] = internet_nl_messages[language].internet_nl[internet_nl_field_mapping[key] + subfield]
      // console.log(`Creating mapping for ${key + subfield}. subfield: ${subfield} with data from: ${internet_nl_field_mapping[key] + subfield}`)
    })
  }
  return mapping;
}


const all_internet_nl_fields = Object.keys(internet_nl_messages['en'].internet_nl)

function get_subtranslations(find_field_name) {
  /*
  * detail_mail_tls_cipher_order_exp
      detail_mail_tls_cipher_order_label
      detail_mail_tls_cipher_order_tech_table
      detail_mail_tls_cipher_order_verdict_bad
      detail_mail_tls_cipher_order_verdict_good
      detail_mail_tls_cipher_order_verdict_na
      detail_mail_tls_cipher_order_verdict_seclevel_bad
      detail_mail_tls_cipher_order_verdict_warning
  * */
  // example input: detail_mail_tls_cipher_order
  // example output: _label, _tech_table, _verdict_bad, _vestartsWitchrdict_good, _verdict_na, _verdict_seclevel_bad, ...
  let subfields = [];

  all_internet_nl_fields.forEach((field_name) => {
    if (field_name.startsWith(find_field_name)) {
      subfields.push(field_name.replace(find_field_name, ""))
    }
  })

  return subfields;
}

function interpolate_translations() {
  // messages here, is the translation that already exists, info from internet_nl_messages is added.
  // note that these translations are not complete: you'll have to add _label and _verdict etc to the
  let translation = messages;

  translation.nl = Object.assign(translation.nl, import_field_translations('nl'));
  translation.en = Object.assign(translation.en, import_field_translations('en'));

  return translation;
}

export default interpolate_translations()

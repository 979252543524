import VueI18n from "vue-i18n";
import Vue from "vue";

import store from './store';

import interpolate_translations from "@/locales/merge_wsm_inl";

// todo: lazy loading.
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.locale || 'en',
  fallbackLocale: 'en',
  // this is needed to silence warnings when usin lazy loading?
  // TODO: find out if this is really needed
  silentFallbackWarn: true,
  // it's requformatDistanceired this is called messages.
  messages: interpolate_translations,
  sharedMessages: {}
});

export default i18n

// Contains fallback messages which will slowly be moved to components. In the end this contains app messages
// and things that are used in many components (which are vulnerabilities etc, which are also in app).

export default {
  en: {
    "maps": "Maps",
    "map": "Map",
    "info": "Info",
    "charts": "Best/Worst",
    "reports": "Reports",
    "statistics": "Statistics",
    "comply-or-explain": "Comply or explain",
    "datasets": "Datasets",
    "scan-monitor": "New Scans",
    "scan-schedule": "Schedule",
    "more": "More",
    "overview": "Overview",
    "sector-report": "Monthly Overview",
    "login-plaza": "Login Plaza",
    "score": "score",

    "Netherlands": "Netherlands",
    "netherlands": "netherlands",
    "NL": "Netherlands",

    menu: {
      "maps": "Maps",
      "map": "Map",
      "info": "Info",
      "charts": "Best/Worst",
      "reports": "Reports",
      "statistics": "Statistics",
      "comply-or-explain": "Comply or explain",
      "datasets": "Datasets",
      "scan-monitor": "New Scans",
      "scan-schedule": "Schedule",
      "more": "More",
      "overview": "Overview",
      "sector-report": "Monthly Overview",
      "login-plaza": "Login Plaza",
      "metric-progress": "Progress",
      "responsible-disclosure": "RD/CVD",
      "tracking-cookies": "Tracking Cookies",
      "report": "Report",
      "comply-or-explain-guidelines": "Comply or explain guidelines",
      "state": "State",
      "leaderboard": "Leaderboard",
      "public-submissions": "Add domains",
    },

    "amount": "Amount",

    // new translations
    footer: {
      content: ""
    },
    back_to_map: "Back to the map",
    week: "week",
    clear: "Clear",
    view_report: "View test results",
    "Unable to connect to the server": "Unable to connect to the server",

    other: "Other",

    _score_error_in_test: "Test error",
    _score_not_tested: "Not tested",
    _score_high: "High risk",
    _score_medium: "Medium risk",
    _score_low: "Low risk",
    _score_good: "Good",
    _score_ok: "Good",
    _score_unknown: "No data available",
    _score_amount_domains: "Domains",
    _score_amount_services: "Services",

    ipv6_score_error_in_test: "Test error",
    ipv6_score_not_tested: "Not tested",
    ipv6_score_high: "Unreachable",
    ipv6_score_medium: "Partially reachable ",
    ipv6_score_low: "Partially reachable",
    ipv6_score_good: "Reachable",
    ipv6_score_ok: "Reachable",
    ipv6_score_unknown: "No data available",
    ipv6_score_amount_domains: "Domains",
    ipv6_score_amount_services: "Services",

    // old datasets and indication of problems during report generation:
    repeated_finding: "Repeated finding. Probably because this url changed IP adresses or has multiple IP " +
      "adresses (common for failover / load-balancing).",

    // common
    organizations: "organizations",
    Organization: "Organization",
    internet_adresses: "domains",
    _services: "online services",
    ipv6_services: "website and email services",

    // german layers... sigh... :)
    bundesland: 'Bundesland',
    regierungsbezirk: 'Regierungsbezirk',
    landkreis_kreis_kreisfreie_stadt: 'landkreis, kreis, kreisfreie stadt',
    samtgemeinde_verwaltungsgemeinschaft: 'Samtgemeinde / Verwaltungsgemeinschaft',
    stadt_gemeinde: 'Stadt Gemeinde',
    stadtbezirk_gemeindeteil_mit_selbstverwaltung: '',
    stadtteil_gemeindeteil_ohne_selbstverwaltung: '',

    // categories:
    confidentiality: "Confidentiality",
    integrity: "Integrity",
    website: "Website",

    "dns_a_aaaa": "Website (http and https)",

    "dns_endpoints": "Services for internet.nl",
    "dns_wildcard": "Wildcard domains",
    "known_subdomains": "New subdomains (known)",
    "subdomains": "New subdomains (OSINT)",

    "internet_nl_mail": "Internet.nl Mail",
    "internet_nl_web": "Internet.nl Web",
    "internet_nl_v2_mail": "Internet.nl Mail",
    "internet_nl_v2_web": "Internet.nl Web",
    "verify_unresolvable": "Revive unresolvable",
    "dns_known_subdomains": "New Subdomains",

    "undefined": "Undefined test state, result ignored.",

    "https": "Website (https)",

    "pop": "E-mail reading (pop)",
    "smtp": "E-mail sending (smtp)",
    "imap": "E-mail reading (imap)",

    "dummy": "Dummy Scanner",

    "internet_nl_not_tested": 'This test did not run, because either a parent test that this test depends on gave a negative result (\'fail\') or not enough information was available to run this test.',

    // issues, are used at various places, the original value comes from the database stored as scan results.

    // DNSSEC
    "dnssec": "Domain name security (DNSSEC)",
    "DNSSEC": "Domain name security (DNSSEC)",
    "dnssec DNSSEC is incorrectly or not configured (errors found).": "DNSSEC is incorrectly or not configured (errors found). This means there might not be a guarantee that this domain name matches what the original author has published.",
    "dnssec DNSSEC seems to be implemented sufficiently.": "DNSSEC seems to be implemented sufficiently. This means that the author of the site has established a strong connection between their content and the domain name.",

    "ftp": "File transfers (FTP)",
    "ftp outdated": "FTP Server only supports insecure SSL protocol. This means that there is very weak encryption which might easily be broken by an attacker. This is just as secure as running no encryption. Upgrade from SSL to TLS.",
    "ftp secure": "FTP Server supports the TLS encryption protocol. This means secure authentication and file transfer is possible.",
    "ftp insecure": "FTP Server does not support encrypted transport or has protocol issues.",
    "ftp unknown": "An FTP connection could not be established properly. Not possible to verify encryption.",

    "bannergrab": "Removal of software version numbers",
    "bannergrab_bannergrab": "Software version",
    "bannergrab service_identity_likely_version_found": "A possible software version number was found, this can be used by an attacker to specifically target this computer. The less information exposed, the harder it is to attack this service directly. When an attack needs more work, it will be easier to detect.",
    "bannergrab service_identity_some_identity_found": "Some identifying information about the software used is found. Attackers can use this information to skip a large amount of possible attacks.",
    "bannergrab service_identity_nothing_found": "No version information found, where in the past some version could be identified. Version information is used by attackers to specifically target computers at scale.",
    "bannergrab service_identity_not_seen_in_a_while": "No version information found, where in the past some version could be identified. Version information is used by attackers to specifically target computers at scale.",

    "internet_nl_web_tls": "Secure connection following NCSC-NL requirements (HTTPS)",

    "ports": "Offer internet services to the right audience",
    "ports port_should_not_be_open": "A service has been discovered that is usually of little to no value of the general public. This should usually only be reachable via a private network. In general, the less exposed to the internet, the better. If this is a legitimate service, it can be mapped to one of the standard ports.",
    "ports port_could_not_be_found_anymore": "This address hosted a service in the past that was not intended for the general public. It has been removed.",

    // used at security headers
    "Stats hasn't": "Does not have",
    "Stats has": "Does have",

    "web_privacy_cookie_products_no_consent": "Placement of cookies",
    "web_privacy_cookie_products_no_consent ok": "No cookies have been received, or no cookies are known to be outside of the functional/analytical scope.",
    "web_privacy_cookie_products_no_consent low": "No cookies are known to be outside of the functional/analytical scope but the source of (some of) these cookies is disputed.",
    "web_privacy_cookie_products_no_consent medium": "Marketing cookies are placed without consent of the visitor. The visitor of the site did not choose to receive these cookies which makes placing them unlawful is most countries. The attached cookie overview shows which cookies have been placed with marketing purpose, these are highlighted with a warning. There you'll also find other information about the cookie, for example the product that places it.",
    "web_privacy_cookie_products_no_consent high": "Marketing cookies are placed without consent of the visitor. The visitor of the site did not choose to receive these cookies which makes placing them unlawful is most countries. The attached cookie overview shows which cookies have been placed with marketing purpose, these are highlighted with a warning. There you'll also find other information about the cookie, for example the product that places it.",
    "web_privacy_cookie_products_no_consent undefined": "An error occurred while performing this test. This test result does not have impact on grading.",

    // http_security_header_strict_transport_security
    "security_headers": "HTTP Security Headers",
    "http_security_headers": "HTTP Security Headers",
    "http_security_header_strict_transport_security": "Strict-Transport-Security Header (HSTS)",
    "http_security_header_strict_transport_security present": "Strict-Transport-Security header present. This means a modern web-browser is automatically pointed to the secure version of the website. This is guaranteed in most cases after the first visit until browsers change their default from http:// to https://.",
    "http_security_header_strict_transport_security missing": "Missing Strict-Transport-Security header. This means that it is easy to visit the insecure version of the website, which means the information you send or receive might be seen and altered by others. Due to prevention of downgrade attacks, HSTS is required for every HTTPS response including redirects, even if there is no insecure endpoint. In the measured data you can see what https request has no corresponding HSTS header.",
    "http_security_header_strict_transport_security missing_but_no_insecure_service_offered": "Missing Strict-Transport-Security header. Yet this website offers no insecure alternative service. This means it is not possible to go to the insecure version of the website.",
    "http_security_header_strict_transport_security unreachable": "Web server content became unreachable. No HTTP content present.",
    "http_security_header_strict_transport_security non_http_response": "Non HTTP response found, which does not require HTTP security headers.",
    "http_security_header_strict_transport_security soap": "Header not relevant for SOAP service.",
    "http_security_header_strict_transport_security access_restricted": "Web server requires authorization to access, headers are not publicly visible.",

    "http_security_header_x_frame_options": "X-Frame Options Header (clickjacking)",
    "http_security_header_x_frame_options present": "X-Frame-Options header is present. This means the content of the website can only be included inside a predefined set of domains.",
    "http_security_header_x_frame_options missing": "Missing X-Frame-Options header. This means that anyone can include the content of this website on other websites, enabling an attacker to mislead visitors.",
    "http_security_header_x_frame_options using_csp": "Content-Security-Policy header found, which covers the security aspect of the X-Frame-Options header. This means decisions are made about what websites can include the the content of this website.",
    "http_security_header_x_frame_options unreachable": "Web server content became unreachable. No HTTP content present.",
    "http_security_header_x_frame_options non_http_response": "Non HTTP response found, which does not require HTTP security headers.",
    "http_security_header_x_frame_options soap": "Header not relevant for SOAP service",
    "http_security_header_x_frame_options access_restricted": "Web server requires authorization to access, headers are not publicly visible.",

    "http_security_header_x_xss_protection": "X-XSS-Protection Header",
    "http_security_header_x_xss_protection present": "X-XSS-Protection header present.",
    "http_security_header_x_xss_protection missing": "Missing X-XSS-Protection header.",
    "http_security_header_x_xss_protection using_csp": "Content-Security-Policy header found, which covers the security aspect of the X-XSS-Protection header.",
    "http_security_header_x_xss_protection unreachable": "Web server content became unreachable. No HTTP content present.",
    "http_security_header_x_xss_protection non_http_response": "Non HTTP response found, which does not require HTTP security headers.",
    "http_security_header_x_xss_protection soap": "Header not relevant for SOAP service",
    "http_security_header_x_xss_protection access_restricted": "Web server requires authorization to access, headers are not publicly visible.",

    "http_security_header_x_content_type_options": "X-Content-Type-Options Header",
    "http_security_header_x_content_type_options present": "X-Content-Type-Options header present. This means downloads are happening the way the author intends.",
    "http_security_header_x_content_type_options missing": "Missing X-Content-Type-Options header. This means there might be confusion between what the author of the site means, and what the browser downloads for you when clicking a download link.",
    "http_security_header_x_content_type_options unreachable": "Web server content became unreachable. No HTTP content present.",
    "http_security_header_x_content_type_options non_http_response": "Non HTTP response found, which does not require HTTP security headers.",
    "http_security_header_x_content_type_options soap": "Header not relevant for SOAP service",
    "http_security_header_x_content_type_options access_restricted": "Web server requires authorization to access, headers are not publicly visible.",

    "plain_http": "Encrypted http transport (HTTPS)",
    "http": "Website (http)",
    "plain_http Site does not redirect to secure url, and has no secure alternative on a standard port.": "Visiting this website is not possible in a secure manner. The secure part of this site is either missing or unreachable due to weird settings. It begs the question if an insecure website is needed at all.",
    "plain_http Has a secure equivalent, which wasn't so in the past.": "There used to be a moment this website was only available as an insecure website. This has been resolved.",
    "plain_http Redirects to a secure site, while a secure counterpart on the standard port is missing.": "Redirects to a secure site, while a secure counterpart on the standard port is missing.",

    "tls_qualys": "Encryption (HTTPS)",
    "tls_qualys_encryption_quality": "Encryption Quality (HTTPS)",
    "tls_qualys_encryption_quality Broken Transport Security, rated F": "Broken Transport Security, rated F. This means that the encryption used is obsolete: it offers no protection against attackers, especially attackers with deeper pockets.",
    "tls_qualys_encryption_quality Less than optimal Transport Security, rated C.": "Less than optimal Transport Security, rated C. This means that while encryption is in use, this might not be secure within the next year.",
    "tls_qualys_encryption_quality Less than optimal Transport Security, rated B.": "Less than optimal Transport Security, rated B. This means that while encryption is in use, this might not be secure within the next year.",
    "tls_qualys_encryption_quality Good Transport Security, rated A-.": "Good Transport Security, rated A-. This means that there is reasonably strong encryption between you and the server: nobody can read along or alter data.",
    "tls_qualys_encryption_quality Good Transport Security, rated A.": "Good Transport Security, rated A. This means that there is strong encryption between you and the server: nobody can read along or alter data.",
    "tls_qualys_encryption_quality Perfect Transport Security, rated A+.": "Perfect Transport Security, rated A+. This means that there is extremely strong encryption between you and the server: nobody can read along or alter data.",
    "tls_qualys_encryption_quality Error occured during scan. No score assigned.": "An error occurred during testing. No rating or score is given to this test result.",

    "tls_qualys_certificate_trusted": "Trust in certificate (HTTPS)",
    "tls_qualys_certificate_trusted Certificate is not trusted.": "Certificate is not trusted. This means that there is no trust relationship between the domain name and the data you see. You should not trust anything on this website. Trust is broken in case of a naming mixup, when the agreed duration of trust is expired and a few other reasons.",
    "tls_qualys_certificate_trusted Certificate is trusted.": "Trusted. This means that you can safely visit this website, as long as the encryption of this site is also strong.",
    "tls_qualys_certificate_trusted Error occured during scan. No score assigned.": "An error occurred during testing. No rating or score is given to this test result.",

    "whois_domain_ownership": "Ownership of web address",
    "whois_domain_ownership whois_internal_ownership": "This domain is owned by the organization itself. Ownership ('registrant') information is retrieved from the 'WHOIS' database of the Dutch national domain registrar SIDN and is published with with permission.",
    "whois_domain_ownership whois_external_ownership": "This domain is owned by another entity. This means people cannot verify this domain actually belongs to the right organization. Additionally: since another entity owns this domain, it could cause business continuity issues in the long run. This domain should be handed over to the official organization. Ownership ('registrant') information is retrieved from the 'WHOIS' database of the Dutch national domain registrar SIDN and is published with with permission.",
    "whois_domain_ownership whois_unknown_ownership": "No ownership information could be retrieved: it has been shielded off. This prevents people to verify if this domain actually belongs to the right organization. The organization must announce publicly they are the owner of this domain in order to establish trust. Ownership ('registrant') information is retrieved from the 'WHOIS' database of the Dutch national domain registrar SIDN and is published with with permission.",


    // Mail
    "dns_mx_no_cname": "E-Mail",
    "dns_mx_no_cname No secure protocols supported": "No secure protocols supported",

    "internet_nl_mail_starttls_tls_available": "Application of encryption of received e-mail",
    "internet_nl_mail_starttls_tls_available_passed_description": "Encryption available. This means that e-mail can be sent to this address securely.",
    "internet_nl_mail_starttls_tls_available_failed_description": "Encryption missing. This means that e-mail sent to this server might be read or altered by intermediates.",

    "internet_nl_mail_auth_spf_exist": "Sender Policy Framework (SPF)",
    "internet_nl_mail_auth_spf_exist_passed_description": "SPF Available. This means that the sending address of an e-mail can be checked by a receiver.",
    "internet_nl_mail_auth_spf_exist_failed_description": "SPF Missing. This means that there is no way to check the sending address of this e-mail. E-mail from this address can be easily faked.",

    "internet_nl_mail_auth_dkim_exist": "DomainKeys Identified Mail (DKIM)",
    "internet_nl_mail_auth_dkim_exist_passed_description": "DKIM Available. This means there is a digital signature inside the email, which can be used to verify the sender.",
    "internet_nl_mail_auth_dkim_exist_failed_description": "DKIM Missing. This means there is no digital signature inside the email, so the sender cannot be verified.",

    "internet_nl_mail_auth_dmarc_exist": "Domain-based Message Auth (DMARC)",
    "internet_nl_mail_auth_dmarc_exist_passed_description": "DMARC Available. This means an automated hotline is available for receivers of e-mail to check if the origin and/or signature aof the sender are correct. Any fake e-mail can be reported automatically to this hotline.",
    "internet_nl_mail_auth_dmarc_exist_failed_description": "DMARC Missing. This means there is no way to notify the organization of any fake e-mail.",
    "Test internet_nl_mail_auth_dmarc_exist resultated in failed": "Detection and prevention of spoofing not enabled.",

    "internet_nl_v6_web_ipv6": "Website IPv6",
    "internet_nl_v6_web_ipv6_label": "Website IPv6",
    "internet_nl_v6_web_ipv6_ready_label": "Website IPv6 Ready",
    "internet_nl_v6_web_ipv6_proof_label": "Website IPv6 Proof",
    "internet_nl_v6_web_ipv6_incompatible_label": "Website IPv6 Incompatible",

    "internet_nl_v6_mail_ipv6": "E-Mail IPv6",
    "internet_nl_v6_mail_ipv6_label": "E-Mail IPv6",
    "internet_nl_v6_mail_ipv6_ready_label": "E-Mail IPv6 Ready",
    "internet_nl_v6_mail_ipv6_proof_label": "E-Mail IPv6 Proof",
    "internet_nl_v6_mail_ipv6_incompatible_label": "E-Mail IPv6 Incompatible",

    "internet_nl_v6_mail_ipv6 incompatible": "The name server and/or mail server is not reachable over IPv6.",
    "internet_nl_v6_mail_ipv6 proof": "Name server is not reachable over IPv6, the mail server is.",
    "internet_nl_v6_mail_ipv6 ready": "Name server and mail server reachable over IPv6.",
    "internet_nl_v6_web_ipv6 incompatible": "Either the name server or web server are not reachable over IPv6, or the site looks different between IP addresses",
    "internet_nl_v6_web_ipv6 proof": "Name server is not reachable over IPv6 but the web server is and the site looks the same on both IP addresses.",
    "internet_nl_v6_web_ipv6 ready": "Name server and web server are reachable over IPv6, the site looks the same on both IP addresses.",

    "internet_nl_wsm_web_appsecpriv_securitytxt": "Publication of where to send security problems",
    "internet_nl_wsm_web_appsecpriv_securitytxt_passed_description": "A security.txt file has been found. A security researcher can use the information in this file to contact the organization responsible for this service.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_info_description": "A security.txt file has been found, but it misses some crucial information. A security researcher might not be able to easily contact the organization, or share information in a secure way.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_missing_on_subdomain_description": "A security.txt file is missing or contains warnings/errors. Review the measured data for more info. This means the owner of this domain might miss out on information about critical vulnerabilities. Due to this being a subdomain, it has been placed at a bit lower priority than having a security.txt file the regular domain. Although: a security researcher might not be able to connect the dots and figure out this subdomain belongs to a domain that does have a security.txt file.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_missing_on_domain_description": "A security.txt file is missing or contains warnings/errors. Review the measured data for more info. This means the owner of this domain might miss out on information about critical vulnerabilities.",

    // Comply or explain, canned messages:
    "service_intentionally_designed_this_way": "This service has been explicitly designed to deviate from standards, which is more secure.",
    "trusted_on_local_device_with_custom_trust_policy": "Trusted on a local device with a custom trust policy of the organization",
    "device_only_certificate": "This certificate is only used on a device",
    "for_devices_only_not_browsers": "This services is intended for devices, not for browsers",
    "for_specialized_applications_only_not_browsers": "This service is intended for specialist applications, not browsers",
    "unencrypted_required": "No encryption required for Certificate Revocation Lists",
    "state_trusted_root_ca": "This certificate is not publicly trusted by design of the state/country",

    internet_nl_web_rpki_exists: "Trust in network route to website",
    internet_nl_mail_rpki_exists: "Trust in network route to e-mail server",

    "web_privacy_third_party_requests": "Website is self-hosted",
    "web_privacy_third_party_requests web_privacy_third_party_found": "When visiting this site, data is being retrieved from third parties. This can be images but also more complex resources such as fonts or scripts. The computers that serve these external resources can see you're visiting this website, and it depends if the company behind that external resource is doing with the information about your visit. They might ignore it, or they might track and sell that data.",
    "web_privacy_third_party_requests web_privacy_third_party_ok": "When visiting this website, no information is retrieved from third parties. This is the best level of privacy a website can provide.",

    "web_privacy_tracking": "Website respects your privacy",
    "web_privacy_tracking web_privacy_tracking_trackers_found": "A visit to this website is being shared with companies that track users in order to make money. Usually this doesn't happen out of bad faith, but because using services from some web-companies are just easy to add when developing a website. Developers of websites usually need things such as usage statistics, therefore a wide range of alternative privacy-friendly tracking tools exist: Matomo, GoAccess, OpenWebAnalytics, AwStats, Plausible and many more. For developers: The links below contain a booklet on how to build privacy respecting websites.",
    "web_privacy_tracking web_privacy_tracking_ok": "A visit to this website is not being shared with companies known for tracking users and making money off of this. Not all of these companies are known, so if data is being requested from third parties, your visit-information might still be shared with these companies.",

    "location_server": "Physical location of server",
    "location_server location_high": "This server is situated in an unusual country or continent. This location is evaluated as being high risk, because of different laws regarding treatment of (personal) data.",
    "location_server location_medium": "This server is situated in an unusual country or continent. This location is evaluated as being medium risk, because of different laws regarding treatment of (personal) data.",
    "location_server location_low": "This server is situated in an unusual country or continent. This location is evaluated as being low risk, because of different laws regarding treatment of (personal) data.",
    "location_server location_ok": "This server is located in a country or continent that fits within the current security policy.",
    "location_server location_unknown": "It's unknown in what country or continent this server is located.",
    "location_server location_clean": "There used to be a server at this location, but this cannot be found anymore. Previous results have been removed.",

    "location_mail_server": "Physical location of e-mail server(s)",
    "location_mail_server location_high": "At least one e-mail server is situated in an unusual country or continent. This location is evaluated as being high risk, because of different laws regarding treatment of (personal) data.",
    "location_mail_server location_medium": "At least one e-mail server is situated in an unusual country or continent. This location is evaluated as being medium risk, because of different laws regarding treatment of (personal) data.",
    "location_mail_server location_low": "At least one e-mail server is situated in an unusual country or continent. This location is evaluated as being low risk, because of different laws regarding treatment of (personal) data.",
    "location_mail_server location_ok": "All e-mail servers on this location are located in a country or continent that fit within the current security policy.",
    "location_mail_server location_unknown": "All mail servers are situated at an unknown location.",
    "location_mail_server location_clean": "Previously this address contained at least one mail server. This is not the case anymore. Therefore this result is shown that the mail server has been cleaned up.",

    "location_third_party_website_content": "Physical location of website sources",
    "location_third_party_website_content location_high": "When visiting this website information is retrieved from foreign parties, which means visitor data is shared. The laws in these countries may not match ours, and we're evaluating these countries as being high risk when sharing that information.",
    "location_third_party_website_content location_medium": "When visiting this website information is retrieved from foreign parties, which means visitor data is shared. The laws in these countries may not match ours, and we're evaluating these countries as being medium risk when sharing that information.",
    "location_third_party_website_content location_low": "When visiting this website information is retrieved from foreign parties, which means visitor data is shared. The laws in these countries may not match ours, and we're evaluating these countries as being low risk when sharing that information.",
    "location_third_party_website_content location_ok": "When visiting this website information is shared with parties that fall within the current security policy.",
    "location_third_party_website_content location_unknown": "No data found or information is only shared to unknown countries.",
    "location_third_party_website_content location_clean": "No information found.",

    "nuclei_exposed_panels": "Removal of management portals",
    "nuclei_exposed_panels ok": "The found management portal is placed online as an intended public service.",
    "nuclei_exposed_panels low": "A management portal has been found, this portal should probably not be placed online. accessible via the public Internet. This means that in case of vulnerabilities, an attacker can abuse the management environment. Also, anyone in the world can try to log into the management environment.",
    "nuclei_exposed_panels medium": "A management environment was found that should not be accessible via the public Internet. This means that in case of vulnerabilities, an attacker can abuse the management environment. Also, anyone in the world can try to log into the management environment.",
    "nuclei_exposed_panels high": "A management environment was found that should not be accessible via the public Internet. This means that in case of vulnerabilities, an attacker can abuse the management environment. Also, anyone in the world can try to log into the management environment.",

    internet_nl_mail_tls: "Secure e-mail transmissiong following NCSC-NL guidelines + DANE",

    "friendly_issue_translations": {
      "nuclei_exposed_panels": {
        "title": "Removal of management portals",
        "explanation": "Management portals are online applications where the owner of a domain can perform management tasks. For example, changing content on the website with a Content Management System or changing settings on the server or in the database.",
        technology: "Firewall / Virtual Private Network (VPN)",
      },
      "internet_nl_mail_tls": {
        "title": "Secure e-mail transmissiong following NCSC-NL guidelines + DANE",
        "explanation": "This checks if it's possible to send e-mail to this address over an encrypted connection. The encryption has to comply with NCSC guidelines and has to use DANE.",
        technology: "Transport Layer Security (TLS)"
      },
      "web_privacy_cookie_products_no_consent": {
        "title": "Placement of cookies",
        "explanation": "Using the attributes of a cookie (name, path and others) a match is made on product and purpose. Details about cookies are documented by their vendor or are implied by the type of product. The cookie metrics are based on the cookies received without user consent, which means placing marketing cookies is illegal in most countries. It's possible to manually verify these metrics by using a browser without an ad blocker and visiting the website: on the developer pane of the browser all received cookies are listed. Given all browsers deal with (tracking) cookies differently, additional settings might be required before receiving all cookies.",
        technology: "HTTP cookies",
      },
      "location_server": {
        "title": "Physical location of server",
        "explanation": "The physical location of this computer is important because laws vary per country. When visiting this internet-address, information is shared with the destination country. This measurement checks what country and continent receives the online visit and matches it with a security policy.",
        technology: "IP Geolocation",
      },
      "location_mail_server": {
        "title": "Physical location of e-mail server(s)",
        "explanation": "The physical location of these computers is important because laws vary per country. When sending an e-mail to this address, information is shared with the destination country. This measurement checks what country and continent receives the online visit and matches it with a security policy",
        technology: "IP Geolocation",
      },
      "location_third_party_website_content": {
        "title": "Physical location of sources of the website",
        "explanation": "When visiting a website certain information such as images of fonts might be retrieved from other sources, including from other countries. This measurement checks what country and continent receives the online visit and matches it with a security policy",
        technology: "IP Geolocation",
      },
      "internet_nl_web_tls": {
        "title": "Secure connection following NCSC-NL requirements (HTTPS)",
        "explanation": "Check if this connection complies to all requirements that are part of the NCSC-NL TLS specification. This specifcation is updated every few years. This test result is based on a dozen individual metrics such as the strength of the security and security settings.",
        technology: "Transport Layer Security (TLS)",
      },
      "dnssec": {
        "title": "Match between the domain name and computer address",
        "explanation": "A domain name is easy to remember, but some way this name has to be translated to a computer address. This happens behind the scenes in a digital phone and address book. There is a very complicated technology that guarantees that a domain name such as <u>www.example.com</u> belongs to a specific computer address. Yet the owner of the domain has to set up this complicated technology themselves.",
        "technology": "Domain Name System Security Extensions (DNSSEC)",
      },
      "tls_qualys_encryption_quality": {
        "title": "The visit to a website stays between you and the website",
        "explanation": "A website should be visited in full trust: nobody should be able to read or alter the information you send or receive. For this encryption is required. This type of encryption is recognizable by the lock in the address bar when visiting the site. Not all types of encryption are as secure: encryption degrades over time, so old encryption means the same as no encryption. A check is performed to see if the encryption is acceptable for todays standards. If it is, all is fine. In case the encryption will degrade soon, this is shown in a different color: it is secure today, but maybe not in a year.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "tls_qualys_certificate_trusted": {
        "title": "Is the security of the website reliable?",
        "explanation": "Encryption is not worth anything if the wrong people can still read what you send and receive. To prevent this, organizations work together to create a trusted network of secure data. The organization behind this website has a trusted relation with the organization that makes encryption possible. Those organizations in turn are accredited, checked and such: so you are able to trust them. When this trust is missing, or the trust has expired, and in some other situations this test will fail. Your browser will usually show a big-red warning screen when visiting sites with security issues.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "whois_domain_ownership": {
        "title": "Ownership of web address",
        "explanation": "A web address or domain can only be owned by a single organization. This must be the organization that is in the end responsible for the content of this domain for two reasons: 1) trust, as everyone can see this domain is not taken by a different/unknown party or malicious actor and 2) business continuity, as an external organization might re-purpose this domain on the long run (after a contract expires for example). Ownership ('registrant') information is retrieved from the 'WHOIS' database of the Dutch national domain registrar SIDN and is published with with permission.",
        technology: "Who is (WHOIS)",
        ratings: {},
      },
      "plain_http": {
        "title": "Websites without encryption",
        "explanation": "When visiting a website without encryption, any information sent and received can be seen and altered by others. This means you cannot trust the information received as there is no confidentiality or integrity. The modern internet usually provides a secure way to transfer information, but there are exceptions or it might be forgotten. That's why it's measured here.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "ftp": {
        "title": "Secure file transfers",
        "explanation": "Exchanging files can happen in many ways. A classic method is using the 'file transfer protocol', which is still popular today. While in the old days encryption was not supported, today it is. This test checks if secure authentication and encrypted file transfer is possible using this classic way of transfering files.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "internet_nl_mail_starttls_tls_available": {
        "title": "Application of encryption of received e-mail",
        "explanation": "When someone sends an e-mail to this address it should be encrypted so intermediates cannot read or alter what is sent. The technology of this encryption is called STARTTLS, which works somewhat the same as encryption on websites.",
        technology: "Opportunistic TLS / STARTTLS",
        ratings: {},
      },
      "internet_nl_mail_auth_dmarc_exist": {
        "title": "The organization has an automated hotline for fake e-mail",
        "explanation": "Fake e-mail is commonly used to unsolicited promotion of products (spam) or deception (scam). Attackers of course want to use the good reputation of companies to pass as legitimate. The companies that are being impersonated of course do not want this. To deter abuse of their name, they can set up an automated hotline to receive information about fake e-mails: mails with missing signatures or sent from the wrong address for example. A company can use this information to, for example, block senders of fake e-mail globally.",
        technology: "Domain-based Message Authentication, Reporting and Conformance (DMARC)",
        ratings: {},
      },
      "internet_nl_mail_auth_dkim_exist": {
        "title": "Sent e-mail contains a signture of the sending organization",
        "explanation": "E-mail can contain a digital signature of the sender, this can by verified by the postal service. If the signature is missing or incorrect, it can be seen as an attempt of sending fake mail. When somebody does send a fake e-mail, the postal service can issue a notification to the real owner of the e-mail address as they might want to do something about this.",
        technology: "DomainKeys Identified Mail (DKIM)",
        ratings: {},
      },
      "internet_nl_mail_auth_spf_exist": {
        "title": "E-mail being sent from a know postal address",
        "explanation": "E-mail should only be sent from a known postal address: a known domain. If somebody else tries to send e-mail in someone elses name, forging the sender, this setting makes sure that fake e-mail is rejected by the postal service. Additionally the owner of the real postal address can get a notification somebody is sending a fake e-mail.",
        technology: "Sender Policy Framework (SPF)",
        ratings: {},
      },
      "http_security_header_strict_transport_security": {
        "title": "The secure version of the website is preferred",
        "explanation": "Because the internet is old, and not all computers are as powerful, it is possible to visit a insecure version of a website. With this setting all modern web browsers are lead to the secure version automatically, even if you type in the insecure address of the site. This is the difference between http:// and http<b>S</b>:// in the address bar. Note the  'S' in the address, which stands for Secure.",
        technology: "HTTP Strict Transport Security (HSTS)",
        ratings: {},
      },
      "http_security_header_x_content_type_options": {
        "title": "Downloading of files can only happen 'as-is'",
        "explanation": "When clicking on a download link, your computer will think of a way how to download the file. This doesn't always happen correctly: sometimes a picture is meant, but a text file is sent. This type of confusion creates an opportunity for an attacker to misdirect a download, where you are downloading something else than intended. With this setting this type of confusion is mitigated.",
        technology: "X-Content-Type-Options",
        ratings: {},
      },
      "http_security_header_x_frame_options": {
        "title": "Website can only be visited from a defined list of domains",
        "explanation": "A website should only be visible when the domain of the site matches. So for example the page and content on <u>example.com</u> should not be able to be included in any other .com domain. Otherwise an attacker may include the content of a trusted website on their own domain, misleading visitors with certain attacks (for example \"clickjacking\"). Applying this standard makes attacks like these harder to impossible. ",
        technology: "X-Frame-Options",
        ratings: {},
      },
      "bannergrab": {
        "title": "Removal of software version numbers",
        "explanation": "Some computers on the interent tell a visitor what software version it runs. This information can easily be used by an attacker: they compare it to a list of known vulnerable version and if there is a match an attack is super easy. For this reason it is important that only the owner of the computer system knows these versions for upgrades and maintenance. If an attacker does not know what version is running, they will have to guess. A guessing game when attacking costs more energy, effort and is more easily detected and deterred. Software versions can leak from any used software and is the root of a mind-boggling number of attacks. Creators and deployers of software have to remove this information, you as an end-user cannot fix this on your end.",
        technology: "Banner grabbing",
        ratings: {},
      },
      "internet_nl_wsm_web_appsecpriv_securitytxt": {
        "title": "Publication of where to send security problems",
        "explanation": "When security researchers find a security problem, they want to contact the owner, so the owner can fix the problem. The owner of a website publishes a file called security.txt at a well known location. If that file is missing, the owner might miss crucial information about a vulnerability on their infrastructure.",
        technology: "Security.txt",
        ratings: {},
      },
      "ports": {
        "title": "Offer internet services to the right audience",
        explanation: "Every time a connection is made over the internet, a port is used. For example: websites run on port 443 and 80. Your computer usually knows what ports are being used, so it is of no bother to you. Organizations might choose to run things on weird, non-standard ports. Usually by mistake. This test checks if weird ports are used and flags weird ports.",
        technology: "Port (computer networking)",
        ratings: {},
      },
      "internet_nl_web_rpki_exists": {
        "title": "Trust in network route to website",
        explanation: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
        technology: "Resource Public Key Infrastructure (RPKI) / Resource Certification",
        ratings: {},
      },
      "internet_nl_mail_rpki_exists": {
        "title": "Trust in network route to e-mail server",
        explanation: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
        technology: "Resource Public Key Infrastructure (RPKI) / Resource Certification",
        ratings: {},
      },
      "web_privacy_third_party_requests": {
        title: "Website is self-hosted",
        explanation: "A self-hosted website does not leak your visit information to a third party. Yet, it often happens that images or other files are being retrieved from other domains. We expect that a website is full self-hosted, which in turn causes the least surprises for the visitors as well as the owner of the site.",
        technology: "HTTP",
        ratings: {},
      },
      "web_privacy_tracking": {
        title: "Website respects your privacy",
        explanation: "A visit to a website has to happen in confidence: a third party should not be able to know you're visiting the site. Yet, there are many companies out there that make money by providing neat services to builders of websites for a very low fee or free: this is sometimes paid for by with the privacy of the visitor. We expect that a visit to a site is not being shared with companies that actively track you (and use that knowledge against you).",
        technology: "Tracking requests / Tracking cookies",
        ratings: {},
      },
      "internet_nl_web_ipv6_ns_address": {
        title: "IPv6-adressen voor nameservers",
      },
      "internet_nl_web_ipv6_ns_reach": {
        title: "IPv6-bereikbaarheid van nameservers",
      },
      "internet_nl_web_ipv6_ws_address": {
        title: "IPv6-adressen voor webserver",
      },
      "internet_nl_web_ipv6_ws_reach": {
        title: "IPv6-bereikbaarheid van webserver",
      },
      "internet_nl_web_ipv6_ws_similar": {
        title: "Gelijke website op IPv6 en IPv4",
      },
      "internet_nl_mail_ipv6_ns_address": {
        title: "IPv6-adressen voor nameservers",
      },
      "internet_nl_mail_ipv6_ns_reach": {
        title: "IPv6-bereikbaarheid van nameservers",

      },
      "internet_nl_mail_ipv6_mx_address": {
        title: "IPv6-adressen voor mailserver(s)",
      },

      "internet_nl_mail_ipv6_mx_reach": {
        title: "IPv6-bereikbaarheid van mailserver(s)",
      }
    },
  },
  nl: {
    "maps": "Kaarten",
    "map": "Kaart",
    "info": "Info",
    "charts": "Beste/Slechtste",
    "reports": "Rapporten",
    "statistics": "Statistieken",
    "comply-or-explain": "Pas toe of leg uit",
    "datasets": "Gegevens",
    "scan-monitor": "Nieuwe scans",
    "scan-schedule": "Schema",
    "more": "Meer",
    "overview": "Overzicht",
    "sector-report": "Maandoverzicht",
    "login-plaza": "Login Plaza",
    "score": "score",

    "Netherlands": "Nederland",
    "netherlands": "nederland",
    "NL": "Nederland",

    menu: {
      "maps": "Kaarten",
      "map": "Kaart",
      "info": "Info",
      "charts": "Beste/Slechtste",
      "reports": "Rapporten",
      "statistics": "Statistieken",
      "comply-or-explain": "Pas toe of leg uit",
      "datasets": "Gegevens",
      "scan-monitor": "Nieuwe scans",
      "scan-schedule": "Schema",
      "more": "Meer",
      "overview": "Overzicht",
      "sector-report": "Maandoverzicht",
      "login-plaza": "Login Plaza",
      "metric-progress": "Voortgang",
      "responsible-disclosure": "RD/CVD",
      "tracking-cookies": "Volgcookies",
      "report": "Rapport",
      "comply-or-explain-guidelines": "Comply or explain richtlijnen",
      "state": "State",
      "leaderboard": "Klassement",
      "public-submissions": "Domeinen toevoegen",
    },

    "amount": "Aantal",


    footer: {
      content: ""
    },
    back_to_map: "Terug naar de kaart",
    week: "week",
    clear: "Wissen",
    view_report: "Testuitslagen bekijken",
    "Unable to connect to the server": "Kon geen verbinding maken met de server",

    other: "Overig",

    _score_error_in_test: "Fout in test",
    _score_not_tested: "Niet getest",
    _score_high: "Hoog risico",
    _score_medium: "Midden risico",
    _score_low: "Laag risico",
    _score_good: "In orde",
    _score_ok: "In orde",
    _score_unknown: "Geen gegevens beschikbaar",
    _score_amount_domains: "Domeinen",
    _score_amount_services: "Diensten",

    ipv6_score_error_in_test: "Fout in test",
    ipv6_score_not_tested: "Niet getest",
    ipv6_score_high: "Onbereikbaar",
    ipv6_score_medium: "Deels bereikbaar",
    ipv6_score_low: "Deels bereikbaar",
    ipv6_score_good: "Bereikbaar",
    ipv6_score_ok: "Bereikbaar",
    ipv6_score_unknown: "Geen gegevens beschikbaar",
    ipv6_score_amount_domains: "Domeinen",
    ipv6_score_amount_services: "Diensten",

    // categories:
    confidentiality: "Vertrouwelijkheid",
    integrity: "Integriteit",
    website: "Website",

    // common
    organizations: "organisaties",
    internet_adresses: "domeinen",
    _services: "online diensten",
    ipv6_services: "website- en emaildiensten",

    "undefined": "Ongedefinieerde uitkomst, dit resultaat wordt genegeerd.",

    // used at statistics page and issue definitions
    "Stats hasn't": "Heeft geen",
    "Stats has": "Heeft",

    // scanner names without any other conclusions
    "dns_a_aaaa": "Website (http en https)",
    "dns_endpoints": "Diensten voor internet.nl",
    "dns_wildcard": "Wildcard domeinen",
    "known_subdomains": "Nieuwe subdomeinen (bekende)",
    "subdomains": "Nieuwe subdomeinen (OSINT)",

    // scanners with output:
    "http": "Website (http)",
    "https": "Website (https)",


    "dummy": "Dummy Scanner",

    // internet.nl fallback for all translations not available
    "internet_nl_not_tested": 'Deze test is niet uitgevoerd, omdat een hogergelegen test waarvan deze test afhankelijk is al een negatief testresultaat (\'gezakt\') gaf, of omdat onvoldoende informatie beschikbaar was om de test uit te kunnen voeren. ',

    // dnssec scanner
    "dnssec": "Domeinnaam beveiliging (DNSSEC)",
    "dnssec DNSSEC is incorrectly or not configured (errors found).": "DNSSEC is niet of niet juist geconfigureerd. Dat betekent dat er geen garantie is dat de informatie die je te zien krijgt op de website (of andere dienst) hoort bij wat de auteur voor ogen heeft.",
    "dnssec DNSSEC seems to be implemented sufficiently.": "DNSSEC lijkt juist geconfigureerd. Dat betekent dat er een sterke band is tussen de inhoud van de website (of andere dienst op dit adres) en het adres.",

    "ftp": "Bestandsoverdracht (FTP)",
    "ftp outdated": "De FTP server ondersteunt alleen het verouderde SSL protocol, gegevens kunnen niet voldoende worden versleuteld. Een aanvaller kan hierdoor mogelijk de versleuteling breken. Dit is net zo veilig als geen versleuteling gebruiken. Stap over naar het veiligere TLS om de verbinding te beveiligingen.",
    "ftp secure": "De FTP server ondersteunt TLS versleuteling. Dat betekent dat er op een veilige manier kan worden ingelogd en bestanden worden uitgewisseld.",
    "ftp insecure": "De FTP server ondersteunt geen versleuteling of heeft fouten in het protocol.",
    "ftp unknown": "Er kon geen verbinding worden gemaakt met deze FTP server, het is niet mogelijk om de veiligheid hiervan te controleren.",

    "bannergrab": "Weghalen versieinformatie van gebruikte software",
    "bannergrab service_identity_likely_version_found": "Een mogelijk software-versienummer is gevonden. Dit kan worden gebruikt door aanvallers om deze computer aan te vallen. Hoe minder informatie een aanvaller kan vinden, hoe meer moeite nodig is voor een aanval. Meer werk betekent dat de aanval makkelijker is te detecteren en stoppen.",
    "bannergrab service_identity_some_identity_found": "Er is een klein beetje informatie gevonden over welke software wordt gebruikt. Een aanvaller kan dit gebruiken om een groot deel van mogelijke aanvallen over te slaan.",
    "bannergrab service_identity_nothing_found": "Geen software-versie gegevens gevonden, terwijl dat in het verleden wel zo was. Versieinformatie wordt vaak door aanvallers gebruikt om te bedenken welke aanval moet worden uitgevoerd.",
    "bannergrab service_identity_not_seen_in_a_while": "Geen software-versie gegevens gevonden, terwijl dat in het verleden wel zo was. Versieinformatie wordt vaak door aanvallers gebruikt om te bedenken welke aanval moet worden uitgevoerd.",

    "internet_nl_web_tls": "Veilige verbinding volgens NCSC-NL eisen (HTTPS)",

    "ports": "Aanbieden van diensten voor de juiste doelgroep",
    "ports port_should_not_be_open": "Er is een dienst gevonden die gebruikerlijk geen meerwaarde heeft voor het grote publiek. Diensten zoals deze zijn normaliter alleen bereikbaar via een beveiligd netwerk. Over het algemeen geldt: hoe minder bereikbaar over het internet, hoe minder beter. Als deze dienst toch voor een breed publiek moet zijn, deel deze dan via een standaard poort, en niet een alternatief.",
    "ports port_could_not_be_found_anymore": "In het verleden stond op dit adres een dienst die mogelijk alleen voor een select publiek nodig was. Deze dienst is inmiddels verwijderd.",

    "http_security_header_strict_transport_security": "Strict-Transport-Security Header (HSTS)",
    "http_security_header_strict_transport_security present": "Strict-Transport-Security instelling is aanwezig. Dit betekent dat een moderne browser automatisch naar de veilige versie van de website gaat. Dit is vaak een garantie na het eerste bezoek. Browsers gaan nog niet altijd standaard naar de veilige website, maar dat gaat veranderen.",
    "http_security_header_strict_transport_security missing": "Mist de Strict-Transport-Security instelling. Bezoekers worden hierdoor niet verplicht om gebruik te maken van de veilge variant van de site. Dit kan betekenen dat gegevens kunnen worden afgeluisterd of aangepast. Om 'downgrade' aanvallen te voorkomen is HSTS verplicht op alle HTTPS antwoorden inclusief doorverwijzingen. Zelfs als er geen http endpoint wordt aangeboden. In de meetgegevens is te zien welk https verzoek geen overeenkomstig HSTS header heeft.",
    "http_security_header_strict_transport_security missing_but_no_insecure_service_offered": "De webserver mist de Strict-Transport-Security instelling, maar dat is niet nodig: er is geen onveilige versie van deze site.",
    "http_security_header_strict_transport_security unreachable": "Web server was niet meer bereikbaar, geen data gevonden en dus geen oordeel.",

    "http_security_header_x_frame_options": "X-Frame-Options Header (Clickjacking)",
    "http_security_header_x_frame_options present": "X-Frame-Options instelling aanwezig. Dat betekent dat de inhoud van deze website alleen op een beperkt aantal domeinen zichtbaar kan zijn.",
    "http_security_header_x_frame_options missing": "X-Frame-Options instelling ontbreekt. Dit betekent dat een andere website de inhoud van deze website kan insluiten. De andere website kan dan doen alsof het de originele website is: dus misleiden.",
    "http_security_header_x_frame_options using_csp": "Content-Security-Policy instelling wordt gebruikt, daarmee kan fijnmazig worden bepaald wat de inhoud van de site hoort te zijn, en waar deze te bekijken is.",
    "http_security_header_x_frame_options unreachable": "Web server was niet meer bereikbaar, geen data gevonden en dus geen oordeel.",

    "http_security_header_x_xss_protection": "X-XSS-Protection Header",
    "http_security_header_x_xss_protection present": "X-XSS-Protection header aanwezig.",
    "http_security_header_x_xss_protection missing": "X-XSS-Protection header ontbreekt.",
    "http_security_header_x_xss_protection using_csp": "Content-Security-Policy header gevonden, welke de opties van de X-XSS-Protection header fijnmaziger kan bepalen.",
    "http_security_header_x_xss_protection unreachable": "Web server was niet meer bereikbaar, geen data gevonden en dus geen oordeel.",

    "http_security_header_x_content_type_options": "X-Content-Type-Options instelling",
    "http_security_header_x_content_type_options present.": "X-Content-Type-Options instelling aanwezig. Dat betekent dat aangeboden downloads worden gedownload op de manier dat de auteur het heeft bedoeld. Wanneer dit ontbreekt kan er verwarring ontstaan over het formaat van een gedownload bestand.",
    "http_security_header_x_content_type_options missing": "X-Content-Type-Options instelling ontbreekt. Dit betekent dat een download van deze site mogelijk verkeerd gaat. Deze verwarring kan in sommige gevallen worden gebruikt voor misleiding.",
    "http_security_header_x_content_type_options unreachable": "Web server was niet meer bereikbaar, geen data gevonden en dus geen oordeel.",

    "plain_http": "Versleutelde http dienst (HTTPS)",
    "plain_http Site does not redirect to secure url, and has no secure alternative on a standard port.": "Het is niet mogelijk om deze website op een veilige manier te benaderen, terwijl de onveilige website wel benaderd kan worden. Dit kan ontstaan door het ontbreken van een veilig alternatief, of door rare instellingen op de veilige site. In dat laatste geval is het vaak de vraag waarom er een onveilige dienst moet worden aangeboden.",
    "plain_http Has a secure equivalent, which wasn't so in the past.": "In het verleden werd op dit anders een onveilige website aangeboden, zonder versleuteling. Dat is inmiddels opgelost.",
    "plain_http Redirects to a secure site, while a secure counterpart on the standard port is missing.": "Bij het bezoeken van deze site wordt je doorgeleid naar de beveiligde site. Het adres is echter niet bereikbaar op een veilige manier, dus werkt niet met https:// in de adresbalk.",

    "tls_qualys_encryption_quality": "Kwaliteit van de versleuteling (HTTPS)",
    "tls_qualys_encryption_quality Less than optimal Transport Security, rated C.": "Deze versleuteling is binnenkort niet sterk meer. Op dit moment is er dus sprake van een veilige verbinding, maar de kans is groot dat dit binnen een jaar niet meer veilig is. De beheerder moet de versleuteling gaan bijwerken naar een sterkere versie.",
    "tls_qualys_encryption_quality Less than optimal Transport Security, rated B.": "Deze versleuteling is binnenkort niet sterk meer. Op dit moment is er dus sprake van een veilige verbinding, maar de kans is groot dat dit binnen een jaar niet meer veilig is. De beheerder moet de versleuteling gaan bijwerken naar een sterkere versie.",
    "tls_qualys_encryption_quality Good Transport Security, rated A-.": "Er wordt redelijk goede versleuteling toegepast. Dat betekent dat een bezoek aan deze website in vertrouwen gebeurd.",
    "tls_qualys_encryption_quality Good Transport Security, rated A.": "Er wordt goede versleuteling toegepast. Dat betekent dat een bezoek aan deze website in vertrouwen gebeurd.",
    "tls_qualys_encryption_quality Perfect Transport Security, rated A+.": "Er wordt extreem goede versleuteling toegepast. Dat betekent dat een bezoek aan deze website in vertrouwen gebeurd.",
    "tls_qualys_encryption_quality Broken Transport Security, rated F": "De versleuteling is sterk verouderd: dit is net zo veilig als geen versleuteling aanbieden. De sterkte van versleuteling degradeert over tijd omdat computers sneller worden. Een aanvaller kan waarschijnlijk gegevens onderscheppen of aanpassen bij een bezoek aan deze website.",
    "tls_qualys_encryption_quality Error occured during scan. No score assigned.": "Er is een fout opgetreden tijdens het meten van deze versleutelde verbinding. Er is geen beoordeling gegeven aan deze verbinding.",

    "tls_qualys_certificate_trusted": "Vertrouwen in het certificaat (HTTPS)",
    "tls_qualys_certificate_trusted Certificate is not trusted.": "Er is geen vertrouwen tussen de inhoud van de website en het domain. Deze twee hebben niets met elkaar te maken. Een eerste bezoek aan deze site laat een waarschuwing zien aan de gebruiker. Het kan zijn dat de afspraak rondom vertrouwen is verlopen (verlopen certificaat), of dat er over een ander domein vertrouwen is afgesproken.",
    "tls_qualys_certificate_trusted Certificate is trusted.": "Deze website kan in vertrouwen worden bezocht, mits de versleuteling van deze site ook sterk is. ",
    "tls_qualys_certificate_trusted Error occured during scan. No score assigned.": "Er is een fout opgetreden tijdens het meten van deze versleutelde verbinding. Er is geen beoordeling gegeven aan deze verbinding.",

    "whois_domain_ownership": "Eigenaar van internetadres",
    "whois_domain_ownership whois_internal_ownership": "Dit domein is eigendom van de juiste organisatie. Informatie over eigenaarschap ('registrant') is afkomstig uit de 'WHOIS' database van Stichting Internet Domeinregistratie Nederland (SIDN) en wordt gepubliceerd met toestemming.",
    "whois_domain_ownership whois_external_ownership": "Dit domein is eigendom van een andere entiteit: een andere organisatie of een persoon. Dit betekent dat hiet niet mogelijk is om te bepalen of dit domein wel bij de organisatie hoort. Daarnaast geeft dat een risico voor bedrijfscontinuiteit: de eigenaar kan iets anders met het domein doen wanneer een overeenkomst afloopt of om andere redenen. Het domein moet worden overgedragen aan een van de verantwoordelijke organisaties. Informatie over eigenaarschap ('registrant') is afkomstig uit de 'WHOIS' database van Stichting Internet Domeinregistratie Nederland (SIDN) en wordt gepubliceerd met toestemming.",
    "whois_domain_ownership whois_unknown_ownership": "Er is geen informatie over de eigenaar gevonden, mogelijk is dit afgeschermd. Hierdoor kan niet worden bepaald of dit domein wel echt bij de juiste organisatie hoort. Een organisatie moet in de domeinadministratie publiek aangeven dat dit domein van hen is. Informatie over eigenaarschap ('registrant') is afkomstig uit de 'WHOIS' database van Stichting Internet Domeinregistratie Nederland (SIDN) en wordt gepubliceerd met toestemming.",

    "dns_mx_no_cname": "E-Mail Server",
    "dns_mx_no_cname No secure protocols supported": "Geen versleuteld protocol ondersteund",

    "internet_nl_mail_starttls_tls_available": "Toepassing van versleuteling van ontvangen e-mail",
    "internet_nl_mail_starttls_tls_available_passed_description": "STARTTLS wordt toegepast. Dat betekent dat een e-mail die wordt verstuurd naar deze server versleuteld kan worden verstuurd.",
    "internet_nl_mail_starttls_tls_available_failed_description": "STARTTLS ontbreekt. Dat betekent dat een e-mail naar dit adres mogelijk wordt gelezen of aangepast door een ander.",

    "internet_nl_mail_auth_spf_exist": "Sender Policy Framework (SPF)",
    "internet_nl_mail_auth_spf_exist_passed_description": "SPF is beschikbaar. Dit betekent dat het afzendadres van de e-mail is te controleren.",
    "internet_nl_mail_auth_spf_exist_failed_description": "SPF ontbreekt. Dat betekent dat het niet mogelijk is om het afzendadres van de e-mail is te controleren. Hierdoor is het makkelijk om namaak e-mail te sturen.",

    "internet_nl_mail_auth_dkim_exist": "DomainKeys Identified Mail (DKIM)",
    "internet_nl_mail_auth_dkim_exist_passed_description": "DKIM is beschikbaar. Dit betekent dat de afzender een digitale handteking in hun e-mails kan gebruiken. Deze handtekening is te controleren: zo weet je of de e-mail echt is.",
    "internet_nl_mail_auth_dkim_exist_failed_description": "DKIM ontbreekt. Dat betekent dat het niet mogelijk is om te controleren of je e-mail echt is.",

    "internet_nl_mail_auth_dmarc_exist": "Domain-based Message Auth (DMARC)",
    "internet_nl_mail_auth_dmarc_exist_passed_description": "DMARC is beschikbaar. Dit betekent dat er een geautomatiseerd meldpunt is voor nagemaakte e-mail. De organisatie achter dit meldpunt kan dit gebruiken om namaak e-mail te beperken.",
    "internet_nl_mail_auth_dmarc_exist_failed_description": "DMARC ontbreekt. Dat betekent dat de organisatie niet in staat is om namaak e-mail te bestrijden.",
    "Test internet_nl_mail_auth_dmarc_exist resultated in failed": "Herkenning en preventie van namaak e-mails niet ingeschakeld.",

    "internet_nl_v6_web_ipv6": "Website Toepassing van IPv6",
    "internet_nl_v6_web_ipv6_label": "Website IPv6",
    "internet_nl_v6_web_ipv6_ready_label": "Website IPv6 Ready",
    "internet_nl_v6_web_ipv6_proof_label": "Website IPv6 Proof",
    "internet_nl_v6_web_ipv6_incompatible_label": "Website IPv6 Incompatible",

    "internet_nl_v6_mail_ipv6": "E-Mail Toepassing van IPv6",
    "internet_nl_v6_mail_ipv6_label": "E-Mail IPv6",
    "internet_nl_v6_mail_ipv6_ready_label": "E-Mail IPv6 Ready",
    "internet_nl_v6_mail_ipv6_proof_label": "E-Mail IPv6 Proof",
    "internet_nl_v6_mail_ipv6_incompatible_label": "E-Mail IPv6 Incompatible",

    "internet_nl_v6_mail_ipv6 incompatible": "Name server en/of mail server is niet bereikbaar via IPv6.",
    "internet_nl_v6_mail_ipv6 proof": "Name server is niet bereikbaar via IPv6, maar de mail server wel.",
    "internet_nl_v6_mail_ipv6 ready": "Name server en mail server zijn bereikbaar via IPv6.",
    "internet_nl_v6_web_ipv6 incompatible": "Ofwel de name server of de web server zijn niet bereikbaar over IPv6, or de site ziet er verschillend uit per adres.",
    "internet_nl_v6_web_ipv6 proof": "Name server is niet bereikbaar via IPv6 maar de web server wel, de site ziet er hetzelfde uit op beide IP addressen.",
    "internet_nl_v6_web_ipv6 ready": "Name server en web server zijn bereikbaar via IPv6, de site ziet er hetzelfde uit op beide IP adressen.",

    "internet_nl_wsm_web_appsecpriv_securitytxt": "Beschikbaarheid meldpunt informatiebeveiliging",
    "internet_nl_wsm_web_appsecpriv_securitytxt_passed_description": "Er is een security.txt bestand gevonden. Een beveiligingsonderzoeker de informatie in dit bestand gebruiken om met de eigenaar van het systeem in contract te komen wanneer er een kwetsbaarheid is gevonden.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_info_description": "Er is een security.txt bestand gevonden, maar er mist wat cruciale informatien in dit bestand. Hierdoor kan een beveiligingsonderzoeker mogelijk niet op een veilige manier contact opnemen met de organisatie. Er zijn dus nog wat verbeteringen nodig.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_missing_on_subdomain_description": "Een security.txt bestand ontbreekt of bevat fouten. Bekijk de gemeten gegevens voor meer details. Dit betekent dat de eigenaar van dit systeem mogelijk informatie over kritieke kwetsbaarheden misloopt. Deze bevinding is lager beoordeeld omdat deze niet op het hoofddomein zit. Hoewel dit eigenlijk onvoldoende is, is het wat teveel van het goede om dit overal uit te rollen voor een organisatie. Daarom beoordelen we nu alleen het hoofddomein als onvoldoende bij een ontbrekend security.txt bestand.",
    "internet_nl_wsm_web_appsecpriv_securitytxt_missing_on_domain_description": "Een security.txt bestand ontbreekt of bevat fouten. Bekijk de gemeten gegevens voor meer details. Dit betekent dat de eigenaar van dit systeem mogelijk informatie over kritieke kwetsbaarheden misloopt.",

    // Comply or explain, canned messages:
    "service_intentionally_designed_this_way": "This service has been explicitly designed to deviate from standards, which is more secure.",
    "trusted_on_local_device_with_custom_trust_policy": "Trusted on a local device with a custom trust policy of the organization",
    "device_only_certificate": "This certificate is only used on a device",
    "for_devices_only_not_browsers": "This services is intended for devices, not for browsers",
    "for_specialized_applications_only_not_browsers": "This service is intended for specialist applications, not browsers",
    "unencrypted_required": "No encryption required for Certificate Revocation Lists",
    "state_trusted_root_ca": "This certificate is not publicly trusted by design of the state/country",

    "web_privacy_third_party_requests": "Website is volledig in eigen beheer",
    "web_privacy_third_party_requests web_privacy_third_party_found": "Bij een bezoek aan deze site worden gegevens opgehaald bij derde partijen. Dat kunnen plaatjes zijn, maar ook allerlei andere zaken zoals lettertypen, scripts en dergelijke. Deze partijen kunnen zien dat vanaf jouw computer deze informatie wordt opgevraagd, maar dat betetekent niet meteen dat deze data wordt verhandeld.",
    "web_privacy_third_party_requests web_privacy_third_party_ok": "Bij een bezoek aan deze site worden geen gegevens gedeeld met derde partijen. Dit is het hoogste niveau van privacy dat een site kan bieden.",

    "web_privacy_tracking": "Websitebezoek respecteert je privacy",
    "web_privacy_tracking web_privacy_tracking_trackers_found": "Een bezoek aan deze site wordt gedeeld aan bedrijven die bekend staan om gebruikers te volgen om hier geld aan te verdienen. Dit komt vaak omdat de bouwer van de site zich richt op functionaliteit en waarschijnlijk niet heeft nagedacht over wat dat met de privacy kan doen. Het hebben van gebruikersstatistieken is voor de eigenaar natuurlijk erg handig, daarom zijn er veel privacy-vriendelijke alternatieven om dit eigen beheer te meten: Matomo, GoAccess, OpenWebAnalytics, AwStats, Plausible en meer. Voor ontwikkelaars: De onderstaande verwijzingen bevatten een boekje over hoe je een privacyvriendelijke website kan maken.",
    "web_privacy_tracking web_privacy_tracking_ok": "Een bezoek aan deze website wordt niet gedeeld aan bedrijven die bekend staan om gebruikers te volgen om hier geld aan te verdienen. Mogelijk worden er wel gegevens opgevraagd bij derde partijen.",

    "location_server": "Fysieke locatie van server",
    "location_server location_high": "Deze server staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een hoog risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_server location_medium": "Deze server staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een midden risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_server location_low": "Deze server staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een laag risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_server location_ok": "Deze server staat in een land of continent die past binnen het huidige veiligheidsbeleid.",
    "location_server location_unknown": "Deze server staat in een onbekende land of continent.",
    "location_server location_clean": "Voorheen stond hier een server, maar deze kunnen we niet terugvinden. De bevindingen zijn geschrapt.",

    "location_mail_server": "Fysieke locatie van e-mail server(s)",
    "location_mail_server location_high": "Minstens een mailserver staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een hoog risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_mail_server location_medium": "Minstens een mailserver staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een midden risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_mail_server location_low": "Minstens een mailserver staat in een ongebruikelijk land of continent. Deze locatie wordt gezien als een laag risico, bijvoorbeeld vanwege het verwerken van (persoons-)gegevens in het buitenland.",
    "location_mail_server location_ok": "Alle mailservers op een locatie die past binnen het huidige veiligheidsbeleid.",
    "location_mail_server location_unknown": "Alle mailservers staan op een onbekende locatie of er zijn geen mailservers gevonden.",
    "location_mail_server location_clean": "Voorheen zijn op dit adres mailservers gevonden, maar deze staan er niet meer. De bevindingen zijn geschrapt.",

    "location_third_party_website_content": "Fysieke locatie van bronnen op de website",
    "location_third_party_website_content location_high": "Bij een bezoek aan deze website worden bezoekersgegevens gedeeld met partijen uit andere landen. De (privacy-) wetgeving in deze landen kan niet overeen komen met het gewenste beleid. Een van deze landen of het continent wordt gezien als een hoog risico.",
    "location_third_party_website_content location_medium": "Bij een bezoek aan deze website worden bezoekersgegevens gedeeld met partijen uit andere landen. De (privacy-) wetgeving in deze landen kan niet overeen komen met het gewenste beleid. Een van deze landen of het continent wordt gezien als een hoog risico.",
    "location_third_party_website_content location_low": "Bij een bezoek aan deze website worden bezoekersgegevens gedeeld met partijen uit andere landen. De (privacy-) wetgeving in deze landen kan niet overeen komen met het gewenste beleid. Een van deze landen of het continent wordt gezien als een hoog risico.",
    "location_third_party_website_content location_ok": "Bij een bezoek aan deze website worden alleen gegevens gedeeld met landen die vallen binnen het huidige veiligheidsbeleid.",
    "location_third_party_website_content location_unknown": "Er zijn geen gegevens gevonden of het is niet bekend met welke andere landen bezoekersgegevens worden gedeeld.",
    "location_third_party_website_content location_clean": "Er zijn geen meetgegevens gevonden.",

    "web_privacy_cookie_products_no_consent": "Geplaatste cookies",
    "web_privacy_cookie_products_no_consent ok": "Er zijn cookies gemeten of de gemeten cookies zijn allemaal functioneel en/of analytisch voor zover bekend.",
    "web_privacy_cookie_products_no_consent low": "Cookies zijn niet buiten het functionele/analytische domein gevonden, maar er zijn cookies gevonden waarvan de bron niet van wordt vertrouwd.",
    "web_privacy_cookie_products_no_consent medium": "Er zijn cookies gevonden met een marketingsdoel. Hier is geen toestemming voor gegeven tijdens het bezoek, deze cookies zijn de geplaatst zonder dat de bezoeker een keuze heeft opgegeven om deze te ontvangen. In het bijgevoegde overzicht van ontvangen volgcookies staan de cookies met marketingsdoeleinden aangegeven met een waarschuwing. Daar is ook te zien van welk product deze cookies afkomstig zijn.",
    "web_privacy_cookie_products_no_consent high": "Er zijn cookies gevonden met een marketingsdoel. Hier is geen toestemming voor gegeven tijdens het bezoek, deze cookies zijn de geplaatst zonder dat de bezoeker een keuze heeft opgegeven om deze te ontvangen. In het bijgevoegde overzicht van ontvangen volgcookies staan de cookies met marketingsdoeleinden aangegeven met een waarschuwing. Daar is ook te zien van welk product deze cookies afkomstig zijn.",
    "web_privacy_cookie_products_no_consent undefined": "Tijdens het testen is er een fout opgetreden. Dit testresultaat heeft geen invloed op de beoodeling.",

    "nuclei_exposed_panels": "Weghalen beheeromgevingen",
    "nuclei_exposed_panels ok": "Er zijn geen beheeromgevingen gevonden.",
    "nuclei_exposed_panels low": "Er is een beheeromgeving gevonden. Deze zijn nog niet beoordeeld of deze vormen een laag risico.",
    "nuclei_exposed_panels medium": "Er is een beheeromgeving gevonden die niet bereikbaar hoort te zijn via het publieke internet. Dat betekent dat bij kwetsbaarheden een aanvaller de beheeromgeving kan misbruiken. Ook kan iedereen ter wereld proberen in te loggen op de beheersomgeving.",
    "nuclei_exposed_panels high": "Er is een beheeromgevingen gevonden die niet bereikbaar hoort te zijn via het publieke internet. Dat betekent dat bij kwetsbaarheden een aanvaller de beheeromgeving kan misbruiken. Ook kan iedereen ter wereld proberen in te loggen op de beheersomgeving.",

    internet_nl_web_rpki_exists: "Vertrouwen in netwerkroute naar website",
    internet_nl_mail_rpki_exists: "Vertrouwen in networkroute naar e-mail server",

    internet_nl_mail_tls: "Versleuteling ontvangen e-mail volgens NCSC richtlijnen + DANE",

    "friendly_issue_translations": {
      "nuclei_exposed_panels": {
        "title": "Weghalen beheeromgevingen",
        "explanation": "Beheeromgevingen zijn online applicaties waarbij de eigenaar van een domein beheerstaken kan uitvoeren. Denk bijvoorbeeld aan wijzigen van inhoud op de website met een Content Management Systeem of het wijzigen van instellingen op de server of in de database.",
        technology: "Firewall / Virtual Private Network (VPN)",
      },
      "internet_nl_mail_tls": {
        "title": "Versleuteling ontvangen e-mail volgens NCSC richtlijnen + DANE",
        "explanation": "Er wordt gecontroleerd of de kwaliteit van de versleuteling bij het sturen van mails naar deze server veilig is volgens de NCSC richtlijnen. Dit omvat een veelheid aan instellingen en kwaliteitseisen zoals het toepassen van sterke versleutelingsalgoritmen en vertrouwen in het gebruikte certificaat.",
        technology: "Transport Layer Security (TLS)"
      },
      "web_privacy_cookie_products_no_consent": {
        "title": "Geplaatste cookies",
        "explanation": "Door de eigenschappen van het cookies (naam, pad en andere) wordt bepaald bij welk product dit hoort. Van veel cookies is bekend welke doelen ze hebben: de leverancier documenteert dat of het is afgeleid uit het doel van het product. In deze metingen kijken we naar cookies die worden ontvangen zonder dat een bezoeker hier toestemming voor heeft gegeven. Wanneer een gebruiker geen toestemming geeft is het plaatsen van cookies voor marketingsdoeleinden verboden. Deze meting is zelf uit te voeren door een browser zonder advertentieblocker te draaien en de site te bezoeken, in het ontwikkeltabblad van de browser zijn alle ontvangen cookies te bekijken. Gezien alle browsers op een andere manier met cookies omgaan kunnen aanvullende instellingen nodig zijn om alle cookies te ontvangen.",
        technology: "HTTP cookies",
      },
      "location_server": {
        "title": "Fysieke locatie van server",
        "explanation": "De fysieke locatie van deze computer is belangrijk omdat wetten en regels per land verschillen. Wanneer je de site bezoekt wordt informatie opgehaald gedeeld met dit land. In deze meting wordt gekeken in welk land de computer staat en of dit land regelgeving heeft die matcht met de onze.",
        technology: "IP Geolocation",
      },
      "location_mail_server": {
        "title": "Fysieke locatie van e-mail server(s)",
        "explanation": "De fysieke locatie van deze computers is belangrijk omdat wetten en regels per land verschillen. Wanneer je een e-mail stuurt wordt deze namelijk ontvangen en verwerkt in dit land. In deze meting wordt gekeken in welk land de computer staat en of dit land regelgeving heeft die matcht met de onze.",
        technology: "IP Geolocation",
      },
      "location_third_party_website_content": {
        "title": "Fysieke locatie van bronnen op de website",
        "explanation": "De fysieke locatie van de informatie op de website zoals plaatjes en lettertypen is interessant, omdat de regels voor informatieverwerking per land verschillen. Bij het bezoek aan deze website wordt mogelijk bezoekersinformatie gedeeld met partijen uit anderen landen. In deze meting wordt gekeken in welk land de computer staat en of dit land regelgeving heeft die matcht met de onze.",
        technology: "IP Geolocation",
      },
      "internet_nl_web_tls": {
        "title": "Veilige verbinding volgens NCSC-NL eisen (HTTPS)",
        "explanation": "Controle of deze bevinding voldoet aan alle beveilingseisen van het NCSC. Deze eisen worden iedere paar jaar bijgewerkt om de veiligheid te blijven garanderen. Deze meting bevat een stuk of 20 submetingen die allemaal moeten slagen. Zie het volledige testrapport bij de meting om deze details te zien.",
        technology: "Transport Layer Security (TLS)",
      },
      "dnssec": {
        "title": "Match tussen domeinnaam en adres",
        "explanation": "Een dommeinnaam is makkelijk te onthouden, maar om te weten welke computer bij deze naam hoort wordt het domeinnaam vertaald naar een computer-adres. Net zoals een postcode past bij een straatnaam. Er is een ingewikkelde truc die garandeert dat de domeinnaam zoals <u>www.example.com</u> hoort bij een specifiek adres, dus niet het adres van een ander die een vals adres opgeeft.",
        "technology": "Domain Name System Security Extensions (DNSSEC)",
        ratings: {},
      },
      "tls_qualys_encryption_quality": {
        "title": "Websitebezoek blijft tussen jou en de website",
        "explanation": "Een website wil je in vertrouwen kunnen bezoeken: niemand kan dan meelezen of gegevens aanpassen die jij ontvangt of verstuurt. Hiervoor is versleuteling nodig. Deze versleuteling is herkenbaar aan het slotje bij het bezoeken van een website. De ene versleuteling is de andere niet, maar ze krijgen wel allemaal hetzelfde slotje. We controleren of de versleuteling ook voldoende is om je privacy en de integriteit van informatie te waarborgen. Soms zien we versleuteling die nu nog sterk is, maar binnen een paar jaar niet meer. Dit wordt met een andere kleur groen weergegeven.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "tls_qualys_certificate_trusted": {
        "title": "Is de versleuteling van de website betrouwbaar?",
        "explanation": "Versleuteling is niets waard als de verkeerde personen alsnog je gegevens kunnen ontsleutelen. Om dit voorkomen wordt bij versleuteling ook gebruik gemaakt van een vertrouwensband tussen organisaties. De organisatie achter de website die je bezoekt heeft zo'n vertrouwensband met een organisatie die versleuteling mogelijk maakt. Die organisaties worden weer gecontroleerd: zonder deze band is het vertrouwen in geheimhouding van jouw informatie in het geding.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "whois_domain_ownership": {
        "title": "Eigenaar van internetadres",
        "explanation": "Een adres op het internet kan slechts in het bezit zijn van een enkele organisatie. Dit moet de organisatie zijn die verantwoordelijk is voor dit adres: de opdrachtgever. Hier zijn twee belangrijke redenen voor: 1) vertrouwen, zodat iedereen kan zien dat het domein niet in het bezit is van vreemden/onbekenden of kwaadwillenden en 2) bedrijfscontinuiteit, gezien de eigenaar alles met het domein mag doen wat ze maar willen, bijvoorbeeld als een contract afloopt of om andere redenen. Informatie over eigenaarschap ('registrant') is afkomstig uit de 'WHOIS' database van Stichting Internet Domeinregistratie Nederland (SIDN) en wordt gepubliceerd met toestemming.",
        technology: "Who is (WHOIS)",
        ratings: {},
      },
      "plain_http": {
        "title": "Websites zonder versleuteling",
        "explanation": "Bezoek je een website zonder versleuteling? Dan kan deze informatie worden afgeluisterd of aangepast: wat je te zien krijgt is niet zomaar te vertrouwen. De verwachting is dat alle websites versleuteld zijn, maar wanneer dat niet zo is, wordt dat in deze meting meegenomen.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "ftp": {
        "title": "Versleuteld bestandsoverdracht (FTP)",
        "explanation": "Het uitwisselen van bestanden kan op veel manieren. Klassieke manieren hiervan worden nog steeds toegepast, maar ook daar is tegenwoordig versleuteling op mogelijk. Wanneer de optie om bestanden versleuteld te versturen via het 'File Transfer Protocol', wordt hier een hoog risico meting getoond. Deze soort van bestandsoverdracht wordt vooral gebruikt tussen organisaties om grote (hoeveelheden) bestanden over te zetten.",
        technology: "Transport Layer Security (TLS)",
        ratings: {},
      },
      "internet_nl_mail_starttls_tls_available": {
        "title": "Toepassing van versleuteling van ontvangen e-mail",
        "explanation": "Wanneer iemand een e-mail probeert te sturen naar dit adres dan hoort dat versleuteld te gaan. Net zoals een bezoek aan een website gebeurt op https in plaats van http. Zo is het ook mogelijk om mail versleuteld te sturen, waar dit type versleuteling STARTTLS heet.",
        technology: "Opportunistic TLS / STARTTLS",
        ratings: {},
      },
      "internet_nl_mail_auth_dmarc_exist": {
        "title": "De organisatie heeft een geautomatiseerd meldpunt voor vervalste e-mail",
        "explanation": "Namaak e-mail word veel gebruikt om mensen op te lichten. Het liefst gebruiken oplichters bekende merknamen om te doen alsof hun mail echt is. Bedrijven kunnen deze namaak in de gaten houden en bestrijden door hiervoor een meldpunt te maken.",
        technology: "Domain-based Message Authentication, Reporting and Conformance (DMARC)",
        ratings: {},
      },
      "internet_nl_mail_auth_dkim_exist": {
        "title": "Verstuurde e-mail bevat een handtekening van de organisatie",
        "explanation": "E-mail wordt verstuurd met een digitale handtekening van de afzender. Deze handtekening kan niet zomaar worden nagemaakt. Wanneer iemand dat toch probeert na te doen zal het postbedrijf de e-mail weigeren en de echte eigenaar waarschuwen over de namaak e-mail.",
        technology: "DomainKeys Identified Mail (DKIM)",
        ratings: {},
      },
      "internet_nl_mail_auth_spf_exist": {
        "title": "E-mail vanaf een bekend postadres",
        "explanation": "E-mail mag alleen worden verstuurd vanaf een bekend postadres: een bekend domein. Als iemand anders dan een namaak e-mail stuurt vanaf een ander postadres, dan wordt deze geweigerd door het postbedrijf en krijgt de echte eigenaar over de namaak e-mail.",
        technology: "Sender Policy Framework (SPF)",
        ratings: {},
      },
      "http_security_header_strict_transport_security": {
        "title": "De veilige versie van de website krijgt voorrang",
        "explanation": "Omdat het internet oud is, en niet alle computers even krachtig zijn, is het soms mogelijk om ook een onveilige versie van een website te bezoeken. Met deze instelling wordt tegen je computer gezegd dat je altijd naar de veilige versie moet gaan, ookal typ je per ongelijk het onveilige adres in. Dit is het verschil tussen http:// en http<b>s</b>:// in de adresbalk. Let op de 's', deze staat voor secure: veilig",
        technology: "HTTP Strict Transport Security (HSTS)",
        ratings: {},
      },
      "http_security_header_x_content_type_options": {
        "title": "Downloaden van bestanden mag alleen zoals aangegeven",
        "explanation": "Wanneer je iets wil downloaden bedenkt je computer op welke manier dat moet gebeuren. Dit gaat helaas niet altijd goed: soms wordt er een foto bedoeld, maar download je een tekstbestand. Deze verwarring kan iemand de mogelijkheid geven om je op het verkeerde been te zetten bij een download. Met deze instelling wordt mogelijke verwarring voorkomen.",
        technology: "X-Content-Type-Options",
        ratings: {},
      },
      "http_security_header_x_frame_options": {
        "title": "Website kan alleen worden bekeken vanaf bepaalde domeinen",
        "explanation": "Een website mag alleen worden bekeken als deze matcht met het bijbehorende domein. Dus de pagina op <u>example.nl</u> mag niet zomaar worden getoond op een ander .nl domein. Als dit wel gebeurd, kan je worden misleid. Deze instelling maakt deze misleiding onmogelijk. ",
        technology: "X-Frame-Options",
        ratings: {},
      },
      "bannergrab": {
        "title": "Weghalen versieinformatie van gebruikte software",
        "explanation": "Sommige computers op het internet vertellen een bezoeker welke versie van welke software ze gebruiken. Deze versie kan een aanvaller vergelijken met een lijst met kwetsbare versies: als dit overeen komt kan er makkelijk een aanval worden uitgevoerd. Het is belangrijk dat alleen de beheerder van de computersystemen weet welke software wordt gebruikt zodat deze is bij te werken als er een nieuwe versie uitkomt. Als een aanvaller niet weet welke versie er draait, moet er veel meer energie worden gestoken in de aanval: deze wordt dan duurder en makkelijker te detecteren en te blokkeren. Softwareversies kunnen lekken vanuit alle soorten software en staan aan de basis van vele aanvallen. Makers en beheerders van software moeten deze informatie verwijderen, als eindgebruiker kan je hier niets aan doen.",
        technology: "Banner grabbing",
        ratings: {},
      },
      "internet_nl_wsm_web_appsecpriv_securitytxt": {
        "title": "Beschikbaarheid meldpunt informatiebeveiliging",
        "explanation": "Wanneer beveiligingsonderzoekers een probleem vinden, willen ze dit graag melden. Door dat te melden kan de eigenaar van het computersysteem een oplossing toepassen. Hiervoor moet de eigenaar van het systeem een bestand aanbieden genaamd security.txt, op een vaste locatie. Wanneer dit bestand ontbreekt kan de eigenaar van het systeem cruciale informatie over kwetsbaarheden mislopen.",
        technology: "Security.txt",
        ratings: {},
      },
      "ports": {
        "title": "Aanbieden van diensten voor de juiste doelgroep",
        explanation: "Bij iedere verbinding op het internet wordt een zogenaamde \"poort\" open gezet om informatie te kunnen delen. Zo zitten websites gebruikelijk op poort 443 en 80. Je computer weet meestal welke poort je moet gebruiken, zodat je hier geen last van hebt. Toch is het mogelijk om websites op aparte, niet standaard, poorten aan te bieden. Dit gebeurd vaak per ongeluk. Deze test kijkt of er van deze rare poorten in gebruik zijn en geeft het aan wanneer dat zo is.",
        technology: "Port (computer networking)",
        ratings: {},
      },
      "internet_nl_web_rpki_exists": {
        "title": "Vertrouwen in route van internetverkeer naar de website",
        explanation: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
        technology: "Resource Public Key Infrastructure (RPKI) / Resource Certification",
        ratings: {},
      },
      "internet_nl_mail_rpki_exists": {
        "title": "Vertrouwen in route van internetverkeer naar e-mail server",
        explanation: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
        technology: "Resource Public Key Infrastructure (RPKI) / Resource Certification",
        ratings: {},
      },
      "web_privacy_third_party_requests": {
        title: "Website is volledig in eigen beheer",
        explanation: "Een website die op zichzelf staat lekt geen gegevens met derde partijen. Toch zie je soms dat er plaatjes en andere informatie op een site vanaf een ander adres af komen. Soms is dat andere adres vertrouwd, soms niet. Wij verwachten dat een site in volledig eigen beheer is, dat zorgt voor de minste verrassingen voor zowel de ontwikkelaar als de bezoeker.",
        technology: "HTTP",
        ratings: {},
      },
      "web_privacy_tracking": {
        title: "Websitebezoek respecteert je privacy",
        explanation: "Het bezoeken van een website moet in principe in vertrouwen gebeuren: een derde partij weet niet dat jij de site bezoekt. Er zijn allerlei bedrijven die het de makers van een site makkelijk maken om een mooier resultaat te maken met weinig werk/kosten, soms wordt dit wordt betaald met de privacy van de bezoeker. Wij verwachten dat een bezoek niet wordt gedeeld met bedrijven die erom bekend staan gebruikers te volgen (en daarmee geld te verdienen).",
        technology: "Tracking requests / Tracking cookies",
        ratings: {},
      }
    },

    internet_nl_mail_rpki_exists_passed_description: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
    internet_nl_web_rpki_exists_passed_description: "The internet consists of all kinds of smaller networks. These networks can be created ad-hoc, which means somebody malicious might do so. To prevent bad actors from taking over parts of the internet, a network of trust and certification is created. This is a bureaucratic network with organizations being accredited by trusted parties.",
  }
};

<template>
  <b-nav-item-dropdown :class="this.$route.meta.menu === 'organization' && 'active'" style="z-index: 200000;">
      <template #button-content>
        <b-icon icon="layers"/>&nbsp;
        <span v-if="$route.params.layer">
          <span v-if="countries.length > 1">{{countries_and_layers[$route.params.country].name}}:&nbsp;</span>
          <span>{{abbreviated_layer}}</span>
        </span>
        <span v-else>{{ $t("choose_layer")}}</span>
      </template>

      <template v-for="country_code_key in Object.keys(countries_and_layers)">
          <template v-if="countries.length > 1">
            <b-dropdown-divider :key="country_code_key" v-if="country_code_key !== Object.keys(countries_and_layers)[0]"></b-dropdown-divider>
            <b-dropdown-item :key="country_code_key" @click="set_state(countries_and_layers[country_code_key].code, countries_and_layers[country_code_key].layers[0])">
              <img :src="countries_and_layers[country_code_key].flag" width="16" height="10">{{ countries_and_layers[country_code_key].name }}
            </b-dropdown-item>
          </template>

          <b-dropdown-item active-class="active" :key='layer'
            :to="`/${$route.meta.menu === 'organization' ? $route.meta.basename : 'map'}/${country_code_key}/${layer}/`"
            v-for="layer in countries_and_layers[country_code_key].layers">
            {{ $t("layer.layer_"+layer) }}
          </b-dropdown-item>
        </template>
    </b-nav-item-dropdown>
</template>
<script>

/*
* "country_and_layers": {
    "NL": {
      "code": "NL",
      "name": "Netherlands",
      "flag": "/static/flags/nl.gif",
      "layers": [
        "municipality",
        "healthcare",
        "province",
        "government"
      ]
    }
* */

export default {

  data: function () {
    return {
      // gets set when a country is selected.
      my_layers: [],

      // country and layers
      countries_and_layers: {},
    }
  },


  methods: {
    set_state: function (country_code, layer_name) {
      // you only want to navigate away on app pages, not on organization pages. On organization pages just
      // switch context:
      if (this.$route.meta.menu === 'app') {
        this.$router.push({name:'map_with_layer_or_landing_page', params: {layer: layer_name, country: country_code}});
      } else {
        // make the link to this page explicit
        this.$router.push(`/${this.$route.meta.basename}/${country_code}/${layer_name}/`);
      }
    },
  },

  props: {
    showTitle: {type: Boolean, default: true},
    showLayer: {type: Boolean, default: false},
    showIfEmptyOrSingle: {type: Boolean, default: true},
  },

  computed: {
    abbreviated_layer(){
      let layer = this.$t("layer.layer_" + this.$route.params.layer);
      if (layer.length > 40){
        const without_abbreviation = layer.replace(/ \(.+\)/, "");
        if (without_abbreviation.length < 40) {
          layer = without_abbreviation;
        } else {
          const abbreviation = layer.replace(/.+\((.+)\)/, '$1');
          if (abbreviation){
            layer = abbreviation;
          } else {
            layer = layer.substring(0,40) + '...'
          }
        }
      }
      return layer;
    },
    countries() {
      return Object.keys(this.countries_and_layers);
    },
    shouldBeVisible() {
      if(this.showIfEmptyOrSingle)
        return true

        if(!this.$route.params.layer){
          return false
        }

      // If there is more than 1 item to show, this will be shown, otherwise it will be hidden as it adds UI clutter
      if(this.countries.length > 1)
        return true

      let items = 0;

      for (const country in this.countries_and_layers) {
          items += this.countries_and_layers[country].layers.length
      }

      return items > 1
    },

  },

  mounted: function () {
    this.countries_and_layers = this.$store.state.config.country_and_layers;

    let first = Object.keys(this.countries_and_layers);
    if (first[0] === undefined)
      return;

    this.my_layers = this.countries_and_layers[first[0]].layers;

    // todo: this is a side effect, factor it out.
    this.$store.commit('change', {layers: this.my_layers});
    // this.selected_country = this.cl[first[0]].code;
  },

  watch: {
    '$store.state.config.country_and_layers': {
      deep: true,
      handler(newVal){
        this.countries_and_layers = newVal;
      }

    }
  }

};
</script>

<i18n>
{
  "en": {
    "country": "Country",
    "layer": "Change",
    "data_selection": "Data Selection",
    "map": "Map",
    "choose_layer": "Maps"

  },
  "nl": {
    "country": "Land",
    "layer": "Gegevens",
    "data_selection": "Selecteer gegevens",
    "map": "Kaart",
    "choose_layer": "Kaarten"
  }
}
</i18n>
